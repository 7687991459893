import SupportSection from "components/Layouts/Support/SupportSection";
import { paths } from "helpers/paths";
import { Link } from "react-router-dom";

export const NotFound = () => {
  const errorImg = "/images/error404.webp";
  return (
    <>
      <section className="notfound container">
        <img src={errorImg} alt="Error Illustration" />
        <p className="small-title">
          Sorry, the page you are looking for could not be found
        </p>
        <Link to={paths.root} className="cta big">
          Let's try something else
        </Link>
        <meta name="robots" content="noindex"></meta>
      </section>
      <SupportSection />
    </>
  );
};

export default NotFound;
