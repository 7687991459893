import paths from "helpers/paths";

interface MenuItem {
  title: string;
  url?: string;
  subMenu?: MenuItem[];
  external?: boolean;
}

const menu: MenuItem[] = [
  {
    title: "Features",
    url: paths.features,
    subMenu: [
      {
        title: "SKULabs Pro",
        url: paths.root,
        subMenu: [
          { title: "Order picking", url: paths.orderPicking },
          {
            title: "Multi-carrier shipping",
            url: paths.shipping,
          },
          {
            title: "Inventory management",
            url: paths.inventoryManagement,
          },
          {
            title: "Order Management",
            url: paths.orderManagement,
          },
          {
            title: "Purchase Orders",
            url: paths.purchaseOrder,
          },
          {
            title: "Warehouse management",
            url: paths.warehouseManagement,
          },
          {
            title: "Catalog management",
            url: paths.catalogManagement,
          },
          { title: "Point of Sale", url: paths.pointOfSale },
          { title: "Batch picking", url: paths.batchPicking },
          { title: "Insights Reporting", url: paths.insightsReporting },
        ],
      },
      // {
      //   title: "SKULabs Inventory",
      //   url: paths.inventory,
      //   subMenu: [
      //     {
      //       title: "Inventory management",
      //       url: paths.inventoryManagement,
      //     },
      //     {
      //       title: "Warehouse management",
      //       url: paths.warehouseManagement,
      //     },
      //     {
      //       title: "Catalog management",
      //       url: paths.catalogManagement,
      //     },
      //   ],
      // },
      // {
      //   title: "SKULabs Shipping",
      //   subMenu: [
      //     { title: "Order picking", url: paths.orderPicking },
      //     {
      //       title: "Order management",
      //       url: paths.orderManagement,
      //     },
      //     { title: "Batch picking", url: paths.batchPicking },
      //   ],
      // },
    ],
  },
  {
    title: "Pricing",
    subMenu: [
      { title: "SKULabs Pro", url: paths.pricing },
      { title: "SKULabs Inventory", url: paths.inventoryPricing },
    ],
  },
  {
    title: "Partners",
    url: paths.partners,
  },
  {
    title: "Who we serve",
    subMenu: [
      {
        title: "Industries",
        subMenu: [
          // { title: "Industrial ", url: paths.industriesLinks.industrial },
          { title: "Fashion ", url: paths.industriesLinks.fashion },
          // { title: "Automotive ", url: paths.industriesLinks.automotive },
          { title: "3PL ", url: paths.industriesLinks.threepl },
          // { title: "Jewelry ", url: paths.industriesLinks.jewelry },
        ],
      },
      // {
      //   title: "User Cases",
      //   subMenu: [{ title: "Test", url: paths.orderPicking }],
      // },
    ],
  },
  // {
  //   title: "Free Tools",
  //   subMenu: [
  //     { title: "Inventory Template", url: paths.inventoryTemplate },
  //     { title: "Shipping Labels Template", url: paths.shippingTemplate },
  //   ],
  // },
  {
    title: "Resources",
    subMenu: [
      { title: "Hardware", url: paths.hardware },
      { title: "Reviews", url: paths.reviews },
      { title: "Academy", url: paths.academyList },
      { title: "FAQ", url: paths.faq },
      { title: "Blog", url: paths.blog, external: true },
      { title: "Help Center", url: paths.support, external: true },
    ],
  },
  {
    title: "AI",
    url: paths.ai,
  },

  {
    title: "About Us",
    url: paths.about,
    // subMenu: [
    //   {
    //     title: "Contact",
    //     url: paths.contacts,
    //   },
    // ],
  },

  // {
  //   title: "Inventory",
  //   url: paths.inventory,
  // },
];

export default menu;
