import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as SkulabsLogo } from "assets/logos/skulabs-logo.svg";
import { ReactComponent as Facebook } from "assets/icons/social/facebook.svg";
import { ReactComponent as X } from "assets/icons/social/x.svg";
import { ReactComponent as LinkedIn } from "assets/icons/social/linkedin.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram.svg";
import { ReactComponent as YouTube } from "assets/icons/social/youtube.svg";
import { footerMenuData, MenuSection } from "data/footerMenuData";
import paths from "helpers/paths";

const Footer = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer>
      <div className="container footer-navigation">
        <div className="footer-contacts">
          <NavLink to="/" className="logo">
            <SkulabsLogo />
          </NavLink>
          <a href="tel:8002432986" className="link">
            (800) 243-2986
          </a>
          <a href="mailto:sales@skulabs.com" className="link">
            sales@skulabs.com
          </a>
          <a href="mailto:support@skulabs.com" className="link">
            support@skulabs.com
          </a>
          <p>12450 NW 39th St Coral Springs, FL, 33065</p>
          <div>
            <p className="small-title">Follow Us</p>
            <div className="social">
              <a
                href="https://www.youtube.com/@SKULabs"
                target="_blank"
                rel="noopener"
                className="social-link"
              >
                <YouTube />
              </a>
              <a
                href="https://www.instagram.com/skulabs/"
                target="_blank"
                rel="noopener"
                className="social-link"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/company/10268879/admin/dashboard/"
                target="_blank"
                rel="noopener"
                className="social-link"
              >
                <LinkedIn />
              </a>
              <a
                href="https://www.facebook.com/people/SKULabs/61559147060308/"
                target="_blank"
                rel="noopener"
                className="social-link"
              >
                <Facebook />
              </a>
              <a
                href="https://x.com/skulabs"
                target="_blank"
                rel="noopener"
                className="social-link"
              >
                <X />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-menu-list">
          {footerMenuData.map((section: MenuSection, sectionIndex: number) => (
            <nav key={sectionIndex}>
              <p className="small-title">{section.title}</p>
              <ul className="footer-menu">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex}>
                    {item.url ? (
                      item.external ? (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={item.className}
                        >
                          {item.title} <sup>🔗</sup>
                        </a>
                      ) : (
                        <NavLink to={item.url} className={item.className}>
                          {item.title}
                        </NavLink>
                      )
                    ) : (
                      <span>{item.title}</span>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          ))}
        </div>
      </div>
      <div className="meta">
        <div className="container">
          <p>© {currentYear} SKULabs LLC. All rights reserved.</p>
          <ul>
            <li>
              <NavLink to={paths.sitemap} className="link">
                Sitemap
              </NavLink>
            </li>
            <li>
              <NavLink to={paths.termsOfUse} className="link">
                Terms of Use
              </NavLink>
            </li>
            <li>
              <NavLink to={paths.privacyPolicy} className="link">
                Privacy Policy
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
