import { Helmet } from "react-helmet";
import { Link, NavLink, useLocation } from "react-router-dom";
import { VideoSection } from "components/Layouts/Integrations";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import SupportSection from "components/Layouts/Support/SupportSection";
import menu from "data/menuData";
import NotFound from "Pages/NotFound";
import featureData from "data/featuresData";

interface FeaturesProps {
  path?: keyof typeof featureData;
}

const Features: React.FC<FeaturesProps> = () => {
  const menuFeature = menu[0];
  const location = useLocation();

  const page = featureData[location.pathname];

  if (!page) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{page.title || "SKULabs Features Overview"}</title>
        <meta
          name="description"
          content={
            page.description ||
            "Learn how SKULabs can help you run your ecommerce business"
          }
        />
      </Helmet>
      <main>
        <div className="page-head container-short">
          <h1>{page.title || "SKULabs Features"}</h1>
          <p className="description">
            {page.description ||
              "Discover how SKULabs can enhance your ecommerce business."}
          </p>
          <LiveDemoForm />
        </div>
        <section className="features">
          <div className="container">
            {page.video && <VideoSection video={page.video} />}
            <div className="features-content">
              <aside className="aside">
                <ul className="features-menu-list">
                  {menuFeature.subMenu?.map((item, index) => (
                    <div key={`${item.url}-${index}`} className="aside-menu">
                      <NavLink to={item.url as string} className="title">
                        {item.title}
                      </NavLink>
                      {item.subMenu && (
                        <ul>
                          {item.subMenu.map((subItem, index) => (
                            <li key={`${index}-${subItem.url}`}>
                              <NavLink
                                to={subItem.url as string}
                                className="link"
                              >
                                {subItem.title}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </ul>
              </aside>

              <div className="features-list">
                {page.content.map((item, index) => (
                  <div key={item.title} className="feature-item">
                    {item.image && (
                      <img
                        src={item.image}
                        alt={item.alt}
                        className="feature-img"
                      />
                    )}
                    <div className="feature-info">
                      {item.link ? (
                        <Link to={item.link}>
                          <h2 className="title">{item.title}</h2>
                        </Link>
                      ) : (
                        <h2 className="title">{item.title}</h2>
                      )}
                      <p>{item.description}</p>
                      {item.link && (
                        <Link to={item.link} className="cta">
                          {item.linkTitle || "About Feature"}
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
      <SupportSection />
    </>
  );
};

export default Features;
