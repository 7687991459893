const reviewsData = [
  {
    logo: "logos/shopify.svg",
    avatar: "reviewers/test-avatar.jpg",
    description:
      "I am beyond impressed with their professionalism and customer service. For those of use with less tech experience learning a new APP can be frustrating. Alex has gone above and beyond in providing not only timely responses but is available in real time as well to help me out.",
    position: "Owner by Polyform",
    name: "Paola Franklin",
  },
  {
    logo: "logos/shopify.svg",
    avatar: "reviewers/test-avatar.jpg",
    description:
      "SKUlabs is a great fulfillment software that can grow with the complexity of the business. It has the ability to manage inventory well, create efficient fulfillment processes, and if they don't have the solution for something they have a strong team that will work with you to develop the solution or put something in place that works while development catches up.",
    position: "Logistics Manager",
    name: "John Doe",
  },
  {
    logo: "logos/capterra.svg",
    avatar: "reviewers/test-avatar.jpg",
    description:
      "No more staff picking errors. The amount of $ this software saves is amazing. No more having to pay for customer return postage for incorrect item sent. Customers are happier. We are happier. The software pays for its self if you consider its zero error tollarance and what would otherwise have to be spent on returns.",
    position: "CEO at BrightGoods",
    name: "Jane Smith",
  },
  {
    logo: "logos/shopify.svg",
    avatar: "reviewers/test-avatar.jpg",
    description:
      "We wanted to share our experience with Skulabs software, as we've been proud users since June 2019. Running a small team, it's paramount for us to have inventory management software that not only streamlines our operations but also enhances our customer service.",
    position: "Logistics Manager",
    name: "John Doe",
  },
  {
    logo: "logos/capterra.svg",
    avatar: "reviewers/test-avatar.jpg",
    description:
      "Overall ease of use - we came from StitchLabs when it was killed-off; SKULabs was just outside of our desired budget, but it delivers ERP grade software at a fraction of the cost. We have utilised SKULabs features to grow our business by over 45% in a single year!",
    position: "CEO at BrightGoods",
    name: "Jane Smith",
  },
];

export default reviewsData;
