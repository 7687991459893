import paths from "helpers/paths";

interface Metrics {
  number: string;
  annotation: string;
}

interface ErrorItem {
  title: string;
  description: string;
}

interface BenefitItem {
  title?: string;
  description?: string;
}

interface BenefitGroup {
  benefits: BenefitItem[];
  image: string;
  link: string;
  linkText: string;
}

export interface IndustryPage {
  seoTitle?: string;
  seoDescription?: string;
  heroTitle: string;
  heroDescription: string;
  heroImg: string;
  heroImgBG: string;
  metrics: Metrics[];
  errorTitle: string;
  errorDescription: string;
  errorImage?: string;
  errorsList: ErrorItem[];
  benefitsTitle: string;
  benefitsDescription: string;
  benefitsGroups: BenefitGroup[];
}

const dataIndustries: Record<string, IndustryPage> = {
  // [paths.industriesLinks.industrial]: {
  //   heroTitle:
  //     "Optimize Your Industrial Supply Chain with Precision and Efficiency",
  //   heroDescription:
  //     "Enhance your industrial operations with SKULabs' cutting-edge inventory and order management solutions. Our platform is designed to streamline workflows, reduce operational costs, and increase accuracy across your supply chain. With real-time data analytics and seamless integration, transform your production and distribution processes into a competitive advantage.",
  //   heroImg: "/images/industries/industrial/industrial-man.webp",
  //   heroImgBG: "/images/industries/industrial/industrial-bg.webp",

  //   metrics: [
  //     {
  //       number: "3x",
  //       annotation: "Faster Manufacturing Order Fulfillment",
  //     },
  //     {
  //       number: "99%",
  //       annotation: "Inventory Accuracy",
  //     },
  //     {
  //       number: "30%",
  //       annotation: "Reduction in Operational Costs",
  //     },
  //   ],

  //   errorTitle: "Key Challenges in Traditional Retail and Wholesale",
  //   errorDescription:
  //     "Without an efficient inventory management system, medical offices often face numerous challenges that can disrupt the quality of patient care and operational efficiency. Here are some common issues:",
  //   errorImage: "/images/industries/industrial/industrial-errors.webp",
  //   errorsList: [
  //     {
  //       title: "Stockouts and Overstocks",
  //       description:
  //         "Difficulty in maintaining the right stock levels leads to stockouts or excess inventory.",
  //     },
  //     {
  //       title: "Manual Tracking Errors",
  //       description:
  //         "Reliance on manual tracking methods results in frequent errors and discrepancies in inventory records.",
  //     },
  //     {
  //       title: "Time-Consuming Inventory Counts",
  //       description:
  //         "Staff spend excessive time conducting manual inventory counts, diverting focus from customer service and sales.",
  //     },
  //     {
  //       title: "Inaccurate Demand Forecasting",
  //       description:
  //         "Inaccurate sales and demand forecasting leads to poor purchasing decisions and inefficient inventory management.",
  //     },
  //     {
  //       title: "Inefficient Reordering Process",
  //       description:
  //         "Manual reordering processes are time-consuming and prone to errors, leading to delayed replenishment.",
  //     },
  //     {
  //       title: "Loss of Inventory",
  //       description:
  //         "High risk of theft, damage, or loss due to inadequate tracking and monitoring systems.",
  //     },
  //   ],

  //   benefitsTitle:
  //     "Transform Your Retail and Wholesale Operations with SKULabs",
  //   benefitsDescription:
  //     "Unlock the full potential of your business with SKULabs' comprehensive solutions that streamline operations, reduce costs, and improve efficiency.",

  //   benefitsGroups: [
  //     {
  //       benefits: [
  //         {
  //           title: "Say Goodbye to Inventory Errors",
  //           description:
  //             "Track and manage your inventory with precision and ease using our automated system, eliminating manual errors and ensuring optimal stock levels.",
  //         },
  //         {
  //           title: "Never Run Out of Stock Again",
  //           description:
  //             "Receive instant alerts when stock levels are low, helping you avoid stockouts and overstock situations effortlessly.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-first.webp",
  //       link: paths.book,
  //       linkText: "Eliminate picking errors",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title: "Keep Your Customers in the Loop",
  //           description:
  //             "Provide your customers with real-time order updates, enhancing transparency and customer satisfaction with up-to-date tracking information.",
  //         },
  //         {
  //           title: "Speed Up Your Order Fulfillment",
  //           description:
  //             "Automate your order processing to minimize handling time and errors, ensuring faster and more accurate fulfillment.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-second.webp",
  //       link: paths.book,
  //       linkText: "Make your business better",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title: "Cut Costs and Boost Profits",
  //           description:
  //             "Leverage our advanced analytics to identify inefficiencies and reduce operational costs, driving greater profitability for your business.",
  //         },
  //         {
  //           title: "Master Your Business Expenses",
  //           description:
  //             "Keep all your expenses in check with our comprehensive tracking tools, making it easier to spot cost-saving opportunities and manage your budget effectively.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-third.webp",
  //       link: paths.book,
  //       linkText: "Start growing today",
  //     },
  //   ],
  // },
  [paths.industriesLinks.fashion]: {
    seoTitle:
      "The e-commerce fashion WMS that helps you process orders efficiently and accurately with ease",
    seoDescription:
      "Optimize your e-commerce fashion business with an inventory management system that ensures easy, accurate, and efficient order processing. Manage inventory and speed up fulfillment with tools tailored for fashion retailers.",
    heroTitle: "Efficient Fashion Inventory, Unlimited Growth",
    heroDescription:
      "Fill in the operational gaps holding you back from scaling your fashion brand. Build customer loyalty and expand your brand with a partner that takes the guess work out of inventory. Get ready for accurate orders, easy fulfillment, and repeatable workflows.",
    heroImg: "/images/industries/fashion/hero-girl.webp",
    heroImgBG: "/images/industries/fashion/hero-bg.webp",

    metrics: [
      {
        number: "35%",
        annotation: "Reduction of Shipping Errors",
      },
      {
        number: "3x",
        annotation: "Faster Fulfillment Processing",
      },
      {
        number: "20%",
        annotation: "Savings in Labor",
      },
    ],

    errorTitle: "Barriers to Scaling Fashion brands",
    errorDescription:
      "You have put in countless hours growing a following, improving your designs and optimizing your sales channel, but now face a challenge you didn't expect, operational inefficiencies are stunting your scaling brand, how many of these issues are you experiencing?",
    errorImage: "/images/industries/fashion/fashion-errors.webp",
    errorsList: [
      {
        title: "Inventory Inaccuracy",
        description:
          "Miscounts and outdated stock levels result in overselling, stockouts, and delays in fulfillment, affecting customer satisfaction.",
      },
      {
        title: "Slow Fulfillment Times",
        description:
          "Inefficient picking, packing, and shipping processes cause delays, reducing customer satisfaction and slowing down the entire fulfillment pipeline.",
      },
      {
        title: "Stockouts and Overstocking",
        description:
          "Poor demand forecasting leads to stock imbalances, resulting in lost sales due to stockouts or wasted capital on excess inventory.",
      },
      {
        title: "Seasonal Demand Fluctuations",
        description:
          "Fashion's seasonality makes it hard to predict inventory needs, leading to either surplus or shortages during peak periods.",
      },
      {
        title: "Returns Management",
        description:
          "High return rates create logistical challenges for restocking and reselling, complicating inventory management and lowering efficiency.",
      },
      {
        title: "Lack of Real-Time Visibility",
        description:
          "Without up-to-date inventory data, retailers struggle to make informed decisions, leading to inefficiencies and missed opportunities.",
      },
    ],

    benefitsTitle: "Scale Your Fashion Brand with the right WMS",
    benefitsDescription:
      "Choosing the right WMS is tough, but SKULabs simplifies it. We've partnered with fashion brands globally, helping them scale with real-time tracking and accurate stock. ur WMS streamlines order picking, packing, and shipping, reducing fulfillment times and errors. Sell across multiple channels while keeping inventory accurate, avoiding overselling. Partener with the right WMS to grow customer loyalty and reach new heights",

    benefitsGroups: [
      {
        benefits: [
          {
            title: "Accurate Inventory Management",
            description:
              "Real-time tracking ensures precise stock levels across all SKUs, preventing overselling and stockouts, which reduces lost sales and costly returns, ultimately boosting revenue.",
          },
          {
            title: "Faster Order Fulfillment",
            description:
              "Streamlined picking, packing, and shipping processes reduce fulfillment times, improving customer satisfaction and driving repeat business, leading to higher profitability.",
          },
          {
            title: "Seamless Multichannel Integration",
            description:
              "Sync inventory across all sales channels, ensuring consistent stock visibility and eliminating discrepancies, which helps prevent lost sales and reduces operational costs, improving overall margins.",
          },
        ],
        image: "/images/industries/benefits-first.webp",
        link: paths.book,
        linkText: "Eliminate picking errors",
      },
      {
        benefits: [
          {
            title: "Lower Shipping Costs",
            description:
              "Improved order accuracy and efficient shipping processes reduce the need for reshipping or compensating for errors, cutting down on overall shipping expenses.",
          },
          {
            title: "Increased Customer Retention",
            description:
              "Faster fulfillment and improved accuracy enhance customer satisfaction, leading to higher retention rates and more repeat purchases, which boost long-term revenue.",
          },
        ],
        image: "/images/industries/benefits-second.webp",
        link: paths.book,
        linkText: "Make your business better",
      },
      {
        benefits: [
          {
            title: "Reduced Operational Costs",
            description:
              "Automation and streamlined workflows reduce manual labor, minimize errors, and lower administrative costs, improving overall efficiency.",
          },
        ],
        image: "/images/industries/benefits-third.webp",
        link: paths.book,
        linkText: "Start growing today",
      },
    ],
  },
  [paths.industriesLinks.threepl]: {
    seoTitle:
      "Manage hundreds of stores with a WMS system designed for the modern 3PL",
    seoDescription:
      "3PL operations depend on real-time inventory visibility which allows them to manage hundreds of stores. Leverage the ability to opt individual SKUs into inventory control. Improve efficiency with detailed shipping reports and reduce errors with a WMS system designed for modern 3PL operations",
    heroTitle: "WMS for Modern 3PL Operations",
    heroDescription:
      "Manage hundreds of stores and take on new customers with a WMS system designed for any size 3PL. SKULabs delivers a solution that can handle large volumes of SKUs while ensuring accurate inventory and order fulfillment all in real time. Optimize your operation top to bottom with robust shipping reports that can improve order accuracy. Our WMS will reduce operational costs with repeatable easy to follow workflows. ",
    heroImg: "/images/industries/threepl/hero-man.webp",
    heroImgBG: "/images/industries/threepl/hero-bg.webp",

    metrics: [
      {
        number: "35%",
        annotation: "Increase in Order Accuracy",
      },
      {
        number: "25%",
        annotation: "Reduction in Shipping Costs",
      },
      {
        number: "30%",
        annotation: "Increase in Warehouse Efficiency",
      },
    ],

    errorTitle: "Complexities for modern 3PL Operations ",
    errorDescription:
      "Navigating shrinking margins, capacity and space constraint and even shipping challenges threatens every modern day 3PL operation. Profitability can be elusive with rising labor and transportation costs, utilizing advanced technology will keep your operation competitive",
    errorImage: "/images/industries/threepl/threepl-errors.webp",
    errorsList: [
      {
        title: "Manage Hundreds of Stores",
        description:
          "Handling inventory across hundreds of client stores without a real-time inventory system leads to stock discrepancies, overselling, and fulfillment delays.",
      },
      {
        title: "SKU-Level Inventory Control",
        description:
          "Tracking thousands of SKUs for different clients, each with unique requirements, makes it difficult to manage inventory accurately, leading to stockouts, overstocking, and operational inefficiencies",
      },
      {
        title: "Sales Channel Integration",
        description:
          "Syncing inventory and orders across multiple sales platforms for various clients can lead to order processing errors, overselling, and backorders if not properly managed.",
      },
      {
        title: "Inaccurate Billing and Reporting",
        description:
          "Poor tracking of storage, shipping, and fulfillment services leads to billing disputes and loss of client trust.",
      },
      {
        title: "Shipping and Fulfillment errors",
        description:
          "Shipping mistakes, such as incorrect items or carriers, increase returns and costs, damaging profitability and client relationships.",
      },
    ],

    benefitsTitle: "Stay Competitive with a Powerful WMS ",
    benefitsDescription:
      "Competing as a 3PL is already hard enough, SKULabs has made it possible to navigate headwinds like rising labor and transportation costs, inventory errors, space constraints. Leveraging our advanced WMS systemyour operation will be able to Handle hundreds of stores, improve inventory control, connect to multiple sales channels, improve reporting with a bevy of shipping reports and decrease fulfillment errors. ",

    benefitsGroups: [
      {
        benefits: [
          {
            title: "Centralized Multi-Client Inventory Management",
            description:
              "Manage inventory and orders for hundreds of stores from a single platform, allowing you to oversee multiple clients with ease and accuracy.",
          },
          {
            title: "Real-Time Inventory Tracking Across Warehouses",
            description:
              "Keep track of stock in real time across multiple warehouses, reducing errors and improving order accuracy, so your clients always know what’s available.",
          },
        ],
        image: "/images/industries/benefits-first.webp",
        link: paths.book,
        linkText: "Eliminate picking errors",
      },
      {
        benefits: [
          {
            title: "Seamless Multi-Channel Integration",
            description:
              "Sync inventory and orders across all sales channels your clients use, ensuring up-to-date stock levels and preventing overselling or backorders.",
          },
          {
            title: "Improved Client Accountability and Reporting",
            description:
              "Generate detailed reports on inventory levels, order fulfillment, and shipping activity, providing clients with accurate data and transparent billing.",
          },
        ],
        image: "/images/industries/benefits-second.webp",
        link: paths.book,
        linkText: "Make your business better",
      },
      {
        benefits: [
          {
            title: "Scalability for Growing Businesses",
            description:
              "Easily scale your operations as your 3PL business grows, adding new clients, SKUs, and warehouses without losing operational efficiency.",
          },
        ],
        image: "/images/industries/benefits-third.webp",
        link: paths.book,
        linkText: "Start growing today",
      },
    ],
  },
  // [paths.industriesLinks.automotive]: {
  //   seoTitle:
  //     "The Automotive Inventory System for Faster, Error-Free Fulfillment and Accurate Order Management",
  //   seoDescription:
  //     "Easily manage your automotive parts inventory with a system designed for precision in a custom industry. SKULabs helps you process orders quickly, keep track of specialized parts, and minimize costly errors, ensuring faster fulfillment and improved customer satisfaction.",
  //   heroTitle:
  //     "Efficient Inventory and Order Management for the Automotive Industry",
  //   heroDescription:
  //     "Transform your automotive business with SKULabs’ powerful inventory and order management solutions tailored for the complexity of the automotive industry. Manage custom parts inventories with ease, reduce order errors, and speed up fulfillment times. Our platform provides real-time stock visibility and integrates seamlessly across sales channels, giving you the tools to meet the specific demands of the automotive sector and keep your customers satisfied.",
  //   heroImg: "/images/industries/automotive/hero-man.webp",
  //   heroImgBG: "/images/industries/automotive/hero-bg.webp",

  //   metrics: [
  //     {
  //       number: "99%",
  //       annotation: "Inventory accuracy",
  //     },
  //     {
  //       number: "27%",
  //       annotation: "Improved stock turnover",
  //     },
  //     {
  //       number: "40%",
  //       annotation: "Faster picking and packing efficiency",
  //     },
  //   ],

  //   errorTitle:
  //     "The Biggest Inventory and Fulfillment Struggles Automotive Parts Retailers Deal With Daily",
  //   errorDescription:
  //     "Automotive parts retailers face unique and complex challenges in managing their inventory and fulfillment processes. With thousands of custom orders, they often deal with parts that are difficult to find or highly specialized, leading to stockouts or delays. Many parts look similar or have subtle differences, increasing the risk of order errors and returns. Additionally, certain parts are compatible with multiple brands or models, making it even harder to manage SKUs accurately. The industry’s reliance on hard-to-find, custom-fit, and interchangeable parts means even small mistakes in inventory tracking can cause costly errors, shipping delays, and frustrated customers.",
  //   errorImage: "/images/industries/automotive/automotive-errors.webp",
  //   errorsList: [
  //     {
  //       title: "Interchangeable Parts for Multiple Brands",
  //       description:
  //         "Some parts fit multiple vehicle models or brands, complicating SKU management and increasing the chances of inventory errors.",
  //     },
  //     {
  //       title: "Custom Orders and Hard-to-Find Parts",
  //       description:
  //         "Managing unique or hard-to-find parts leads to frequent stockouts or delays in sourcing, impacting customer satisfaction.",
  //     },
  //     {
  //       title: "High SKU Volume",
  //       description:
  //         "With thousands of SKUs for different parts, sizes, and models, keeping track of inventory can become overwhelming, leading to inaccurate stock levels.",
  //     },
  //     {
  //       title: "Supplier Lead Times",
  //       description:
  //         "Long lead times from suppliers for specific or custom parts further complicate inventory planning and can result in stock shortages.",
  //     },
  //     {
  //       title: "Manual Inventory Tracking Errors",
  //       description:
  //         "Relying on manual processes leads to inaccuracies in stock counts, causing over- or understocking and missed sales opportunities.",
  //     },
  //     {
  //       title: "Custom Orders and Hard-to-Find Parts",
  //       description:
  //         "Managing unique or hard-to-find parts leads to frequent stockouts or delays in sourcing, impacting customer satisfaction.",
  //     },
  //   ],

  //   benefitsTitle:
  //     "Improve Your Automotive Parts Inventory and Order Management with SKULabs",
  //   benefitsDescription:
  //     "SKULabs improves automotive parts retailers' operations by providing real-time inventory tracking, reducing the chances of errors with similar-looking or interchangeable parts. With automated workflows, SKULabs speeds up order fulfillment, ensuring custom and hard-to-find parts are processed and shipped quickly. By syncing inventory across multiple sales channels, SKULabs prevents overselling and backorders, giving you total visibility over stock levels. This leads to fewer returns, better inventory accuracy, and more satisfied customers. SKULabs also helps manage the complexity of high SKU volumes, reducing manual errors and making it easier to keep up with supplier lead times, ensuring that you always have the right parts available.",

  //   benefitsGroups: [
  //     {
  //       benefits: [
  //         {
  //           title: "Reduced Overstocking Costs",
  //           description:
  //             "SKULabs helps predict demand for parts, reducing the chances of overstocking slow-moving inventory, which frees up capital and reduces the cost of storing excess stock.",
  //         },
  //         {
  //           title: "Improved Cash Flow",
  //           description:
  //             "By ensuring optimal stock levels through real-time data and automated reordering, SKULabs reduces tied-up capital in overstock and helps maintain steady cash flow.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-first.webp", //todo
  //       link: paths.book,
  //       linkText: "Eliminate picking errors",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title: "Fewer Inventory Write-Offs",
  //           description:
  //             "Accurate stock tracking reduces losses from misplaced or forgotten items in your warehouse, lowering the number of inventory write-offs and improving profitability.",
  //         },
  //         {
  //           title: "Reduced Shipping Costs",
  //           description:
  //             "Faster fulfillment and improved accuracy enhance customer satisfaction, leading to higher retention rates and more repeat purchases, which boost long-term revenue.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-second.webp", //todo
  //       link: paths.book,
  //       linkText: "Make your business better",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title:
  //             "Faster Order Fulfillment, Leading to Higher Customer Retention",
  //           description:
  //             "By automating picking, packing, and shipping processes, SKULabs reduces fulfillment times, which leads to faster deliveries and happier customers who are more likely to become repeat buyers.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-third.webp", //todo
  //       link: paths.book,
  //       linkText: "Start growing today",
  //     },
  //   ],
  // },
  // [paths.industriesLinks.threepl]: {
  //   seoTitle:
  //     "The 3PL Inventory and Shipping Solution for Managing Hundreds of Stores with Precision",
  //   seoDescription:
  //     "SKULabs helps 3PL providers manage inventory and shipping for hundreds of stores with real-time visibility, SKU-level control, and detailed shipping reports. Improve efficiency, reduce shipping errors, and optimize fulfillment operations with a platform designed for the complexities of 3PL logistics.",
  //   heroTitle:
  //     "Efficient Multi-Store Inventory and Shipping Management for 3PL Providers",
  //   heroDescription:
  //     "SKULabs empowers 3PL providers to seamlessly manage inventory and fulfillment for hundreds of stores from one platform. With advanced features like SKU-level control, real-time inventory tracking, and comprehensive shipping reports, you can improve order accuracy, reduce operational costs, and deliver faster, more reliable fulfillment. Optimize your 3PL operations and keep clients satisfied with a powerful, all-in-one solution tailored for logistics providers.",
  //   heroImg: "/images/industries/fashion/hero-girl.webp", //todo
  //   heroImgBG: "/images/industries/fashion/hero-bg.webp", //todo

  //   metrics: [
  //     {
  //       number: "100+",
  //       annotation: "Stores managed from one platform",
  //     },
  //     {
  //       number: "50%",
  //       annotation: "Faster order processing and fulfillment",
  //     },
  //     {
  //       number: "25%",
  //       annotation: "Labor cost savings through automation",
  //     },
  //   ],

  //   errorTitle:
  //     "Overcoming the Biggest Inventory and Shipping Challenges 3PL Providers Face with SKULabs",
  //   errorDescription:
  //     "3PL providers face significant challenges in managing inventory and shipping across hundreds of stores and clients. These include handling large volumes of SKUs with varying needs, ensuring accurate stock levels for multiple clients, and avoiding costly shipping errors. Managing custom orders and specialized SKUs requires precise tracking, while synchronizing inventory across multiple sales channels can lead to stock discrepancies and overselling. Additionally, without detailed shipping reports, optimizing carrier performance and reducing shipping costs can be difficult, often resulting in delays and increased operational expenses. Balancing all these factors makes efficient management of multi-client fulfillment operations complex and resource-intensive.",
  //   errorImage: "/images/industries/fashion/fashion-errors.webp", //todo
  //   errorsList: [
  //     {
  //       title: "Manage Hundreds of Stores Simultaneously",
  //       description:
  //         "Handling inventory for multiple clients across hundreds of stores can be overwhelming without a centralized system, leading to stock discrepancies, overselling, and delays in fulfillment.",
  //     },
  //     {
  //       title: "SKU-Level Inventory Control",
  //       description:
  //         "Keeping track of thousands of SKUs for different clients, each with unique requirements, makes it difficult to manage inventory accurately, leading to stockouts, overstocking, and operational inefficiencies.",
  //     },
  //     {
  //       title: "Difficulty Integrating Multiple Sales Channels",
  //       description:
  //         "Syncing inventory and orders across multiple sales platforms for various clients can lead to order processing errors, overselling, and backorders if not properly managed.",
  //     },
  //     {
  //       title: "Inaccurate Billing and Reporting for Clients",
  //       description:
  //         "Without accurate tracking and reporting, billing clients for services such as storage, shipping, and fulfillment can become a pain point, leading to disputes and loss of trust.",
  //     },
  //     {
  //       title: "Shipping Errors and Inaccurate Fulfillment",
  //       description:
  //         "Without a robust system in place, shipping mistakes such as wrong items, incorrect quantities, or using inefficient carriers increase return rates and shipping costs, impacting client relationships and profitability.",
  //     },
  //   ],

  //   benefitsTitle:
  //     "Seamless 3PL Inventory and Shipping Management with SKULabs",
  //   benefitsDescription:
  //     "SKULabs empowers 3PL providers to overcome the complexities of managing inventory and shipping for multiple clients. With real-time inventory tracking, SKU-level control, and seamless multi-store management, SKULabs ensures that your clients' orders are fulfilled accurately and on time. Detailed shipping reports help you optimize carrier performance, reduce costs, and improve delivery times. By automating manual tasks and integrating multiple sales channels, SKULabs boosts efficiency, reduces errors, and scales effortlessly with your business. Say goodbye to stock discrepancies, shipping errors, and operational inefficiencies, and deliver exceptional service to your clients with SKULabs.",

  //   benefitsGroups: [
  //     {
  //       benefits: [
  //         {
  //           title: "Centralized Multi-Client Inventory Management",
  //           description:
  //             "Manage inventory and orders for hundreds of stores from a single platform, allowing you to oversee multiple clients with ease and accuracy.",
  //         },
  //         {
  //           title: "Real-Time Inventory Tracking Across Warehouses",
  //           description:
  //             "Keep track of stock in real time across multiple warehouses, reducing errors and improving order accuracy, so your clients always know what’s available.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-first.webp", //todo
  //       link: paths.book,
  //       linkText: "Eliminate picking errors",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title: "Seamless Multi-Channel Integration",
  //           description:
  //             "Sync inventory and orders across all sales channels your clients use, ensuring up-to-date stock levels and preventing overselling or backorders.",
  //         },
  //         {
  //           title: "Scalability for Growing Businesses",
  //           description:
  //             "Easily scale your operations as your 3PL business grows, adding new clients, SKUs, and warehouses without losing operational efficiency.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-second.webp", //todo
  //       link: paths.book,
  //       linkText: "Make your business better",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title: "Improved Client Accountability and Reporting",
  //           description:
  //             "Generate detailed reports on inventory levels, order fulfillment, and shipping activity, providing clients with accurate data and transparent billing.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-third.webp", //todo
  //       link: paths.book,
  //       linkText: "Start growing today",
  //     },
  //   ],
  // },
  // [paths.industriesLinks.jewelry]: {
  //   seoTitle: "Let your Inventory Shine with SKULabs",
  //   seoDescription:
  //     "SKULabs helps 3PL providers manage inventory and shipping for hundreds of stores with real-time visibility, SKU-level control, and detailed shipping reports. Improve efficiency, reduce shipping errors, and optimize fulfillment operations with a platform designed for the complexities of 3PL logistics.",
  //   heroTitle: "Accurate and Efficient Jewelry Inventory Management",
  //   heroDescription:
  //     "SKULabs offers jewelry retailers a robust platform to manage their valuable inventory with precision. Track high-value pieces, prevent stockouts, and speed up fulfillment. With real-time inventory updates and seamless integration across sales channels, SKULabs helps you deliver accurate orders, reduce errors, and keep your customers satisfied.",
  //   heroImg: "",
  //   heroImgBG: "/images/industries/jewelry/hero-bg.webp",

  //   metrics: [
  //     {
  //       number: "100+",
  //       annotation: "Stores managed from one platform",
  //     },
  //     {
  //       number: "50%",
  //       annotation: "Faster order processing and fulfillment",
  //     },
  //     {
  //       number: "25%",
  //       annotation: "Labor cost savings through automation",
  //     },
  //   ],

  //   errorTitle:
  //     "Key Challenges Jewelry E-Commerce Businesses Face in Inventory Management",
  //   errorDescription:
  //     "Jewelry retailers face unique challenges in managing inventory due to the high value of items and their variations in size, style, and materials. Managing high SKU counts, ensuring the security of valuable stock, and fulfilling custom orders quickly and accurately are ongoing challenges in this industry.",
  //   errorImage: "/images/industries/jewelry/jewelry-errors.webp",
  //   errorsList: [
  //     {
  //       title: "Manage Hundreds of Stores Simultaneously",
  //       description:
  //         "Handling inventory for multiple clients across hundreds of stores can be overwhelming without a centralized system, leading to stock discrepancies, overselling, and delays in fulfillment.",
  //     },
  //     {
  //       title: "SKU-Level Inventory Control",
  //       description:
  //         "Keeping track of thousands of SKUs for different clients, each with unique requirements, makes it difficult to manage inventory accurately, leading to stockouts, overstocking, and operational inefficiencies.",
  //     },
  //     {
  //       title: "Difficulty Integrating Multiple Sales Channels",
  //       description:
  //         "Syncing inventory and orders across multiple sales platforms for various clients can lead to order processing errors, overselling, and backorders if not properly managed.",
  //     },
  //     {
  //       title: "Inaccurate Billing and Reporting for Clients",
  //       description:
  //         "Without accurate tracking and reporting, billing clients for services such as storage, shipping, and fulfillment can become a pain point, leading to disputes and loss of trust.",
  //     },
  //     {
  //       title: "Shipping Errors and Inaccurate Fulfillment",
  //       description:
  //         "Without a robust system in place, shipping mistakes such as wrong items, incorrect quantities, or using inefficient carriers increase return rates and shipping costs, impacting client relationships and profitability.",
  //     },
  //   ],

  //   benefitsTitle:
  //     "Seamless 3PL Inventory and Shipping Management with SKULabs",
  //   benefitsDescription:
  //     "SKULabs empowers 3PL providers to overcome the complexities of managing inventory and shipping for multiple clients. With real-time inventory tracking, SKU-level control, and seamless multi-store management, SKULabs ensures that your clients' orders are fulfilled accurately and on time. Detailed shipping reports help you optimize carrier performance, reduce costs, and improve delivery times. By automating manual tasks and integrating multiple sales channels, SKULabs boosts efficiency, reduces errors, and scales effortlessly with your business. Say goodbye to stock discrepancies, shipping errors, and operational inefficiencies, and deliver exceptional service to your clients with SKULabs.",

  //   benefitsGroups: [
  //     {
  //       benefits: [
  //         {
  //           title: "Centralized Multi-Client Inventory Management",
  //           description:
  //             "Manage inventory and orders for hundreds of stores from a single platform, allowing you to oversee multiple clients with ease and accuracy.",
  //         },
  //         {
  //           title: "Real-Time Inventory Tracking Across Warehouses",
  //           description:
  //             "Keep track of stock in real time across multiple warehouses, reducing errors and improving order accuracy, so your clients always know what’s available.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-first.webp", //todo
  //       link: paths.book,
  //       linkText: "Eliminate picking errors",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title: "Seamless Multi-Channel Integration",
  //           description:
  //             "Sync inventory and orders across all sales channels your clients use, ensuring up-to-date stock levels and preventing overselling or backorders.",
  //         },
  //         {
  //           title: "Scalability for Growing Businesses",
  //           description:
  //             "Easily scale your operations as your 3PL business grows, adding new clients, SKUs, and warehouses without losing operational efficiency.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-second.webp", //todo
  //       link: paths.book,
  //       linkText: "Make your business better",
  //     },
  //     {
  //       benefits: [
  //         {
  //           title: "Improved Client Accountability and Reporting",
  //           description:
  //             "Generate detailed reports on inventory levels, order fulfillment, and shipping activity, providing clients with accurate data and transparent billing.",
  //         },
  //       ],
  //       image: "/images/industries/benefits-third.webp", //todo
  //       link: paths.book,
  //       linkText: "Start growing today",
  //     },
  //   ],
  // },
};

export default dataIndustries;
