const logos = {
  amazon: {
    primary: () =>
      import("assets/brand_logos/integrations/amazon.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/amazon-square.svg").then(
        (module) => module.default
      ),
  },
  shift4shop: {
    primary: () =>
      import("assets/brand_logos/integrations/shift4shop.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/shift4shop-square.svg").then(
        (module) => module.default
      ),
  },
  ebay: {
    primary: () =>
      import("assets/brand_logos/integrations/ebay.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/ebay-square.svg").then(
        (module) => module.default
      ),
  },
  bigcommerce: {
    primary: () =>
      import("assets/brand_logos/integrations/bigcommerce.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/bigcommerce-square.svg").then(
        (module) => module.default
      ),
  },
  duoplane: {
    primary: () =>
      import("assets/brand_logos/integrations/duoplane.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/duoplane-square.svg").then(
        (module) => module.default
      ),
  },

  fedex: {
    primary: () =>
      import("assets/brand_logos/integrations/fedex.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/fedex-square.svg").then(
        (module) => module.default
      ),
  },
  apc: {
    primary: () =>
      import("assets/brand_logos/integrations/apc.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/apc-square.svg").then(
        (module) => module.default
      ),
  },
  aramex: {
    primary: () =>
      import("assets/brand_logos/integrations/aramex.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/aramex-square.svg").then(
        (module) => module.default
      ),
  },
  arrowxl: {
    primary: () =>
      import("assets/brand_logos/integrations/arrowxl.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/arrowxl-square.svg").then(
        (module) => module.default
      ),
  },
  asendia: {
    primary: () =>
      import("assets/brand_logos/integrations/asendia.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/asendia-square.svg").then(
        (module) => module.default
      ),
  },
  australiapost: {
    primary: () =>
      import("assets/brand_logos/integrations/australia_post.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/australiapost-square.svg").then(
        (module) => module.default
      ),
  },
  authorizedotnet: {
    primary: () =>
      import("assets/brand_logos/integrations/autorizenet.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/autorizenet-square.svg").then(
        (module) => module.default
      ),
  },
  canadapost: {
    primary: () =>
      import("assets/brand_logos/integrations/canada_post.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/canadapost-square.svg").then(
        (module) => module.default
      ),
  },
  canpar: {
    primary: () =>
      import("assets/brand_logos/integrations/canpar.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/canpar-square.svg").then(
        (module) => module.default
      ),
  },
  colisprive: {
    primary: () =>
      import("assets/brand_logos/integrations/colis_prive.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/colisprive-square.svg").then(
        (module) => module.default
      ),
  },
  couriersplease: {
    primary: () =>
      import("assets/brand_logos/integrations/couriersplease.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/couriersplease-square.svg").then(
        (module) => module.default
      ),
  },
  dhl: {
    primary: () =>
      import("assets/brand_logos/integrations/dhl.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/dhl-square.svg").then(
        (module) => module.default
      ),
  },
  dpd: {
    primary: () =>
      import("assets/brand_logos/integrations/dpd.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/dpd-square.svg").then(
        (module) => module.default
      ),
  },
  easypost: {
    primary: () =>
      import("assets/brand_logos/integrations/easypost.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/easypost-square.svg").then(
        (module) => module.default
      ),
  },
  endicia: {
    primary: () =>
      import("assets/brand_logos/integrations/endicia.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/endicia-square.svg").then(
        (module) => module.default
      ),
  },
  etsy: {
    primary: () =>
      import("assets/brand_logos/integrations/etsy.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/etsy-square.svg").then(
        (module) => module.default
      ),
  },
  fastway: {
    primary: () =>
      import("assets/brand_logos/integrations/fastway.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/fastway-square.svg").then(
        (module) => module.default
      ),
  },
  flxpoint: {
    primary: () =>
      import("assets/brand_logos/integrations/flxpoint.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/flxpoint-square.svg").then(
        (module) => module.default
      ),
  },
  ftp: {
    primary: () =>
      import("assets/brand_logos/integrations/ftp.svg").then(
        (module) => module.default
      ),
    // square: () => import("assets/brand_logos/square/ftp-square.svg").then(module => module.default),
  },
  googleDocs: {
    primary: () =>
      import("assets/brand_logos/integrations/google-sheets.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/google-square.svg").then(
        (module) => module.default
      ),
  },
  indiegogo: {
    primary: () =>
      import("assets/brand_logos/integrations/indiegogo.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/indiegogo-square.svg").then(
        (module) => module.default
      ),
  },
  kickstarter: {
    primary: () =>
      import("assets/brand_logos/integrations/kickstarter.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/kickstarter-square.svg").then(
        (module) => module.default
      ),
  },
  lso: {
    primary: () =>
      import("assets/brand_logos/integrations/lso.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/lso-square.svg").then(
        (module) => module.default
      ),
  },
  mondialrelay: {
    primary: () =>
      import("assets/brand_logos/integrations/mondial_relay.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/mondialrelay-square.svg").then(
        (module) => module.default
      ),
  },
  ninjavan: {
    primary: () =>
      import("assets/brand_logos/integrations/ninjavan.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/ninjavan-square.svg").then(
        (module) => module.default
      ),
  },
  norco: {
    primary: () =>
      import("assets/brand_logos/integrations/norco.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/norcodelivery-square.png").then(
        (module) => module.default
      ),
  },
  nzpost: {
    primary: () =>
      import("assets/brand_logos/integrations/nzpost.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/nzpost-square.svg").then(
        (module) => module.default
      ),
  },
  parcelforce: {
    primary: () =>
      import("assets/brand_logos/integrations/parcelforce.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/parcelforce-square.svg").then(
        (module) => module.default
      ),
  },
  paypal: {
    primary: () =>
      import("assets/brand_logos/integrations/paypal.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/paypal-square.svg").then(
        (module) => module.default
      ),
  },
  postmates: {
    primary: () =>
      import("assets/brand_logos/integrations/postmates.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/postmates-square.svg").then(
        (module) => module.default
      ),
  },
  purolator: {
    primary: () =>
      import("assets/brand_logos/integrations/purolator.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/purolator-square.svg").then(
        (module) => module.default
      ),
  },
  quickbooks: {
    primary: () =>
      import("assets/brand_logos/integrations/quickbooks.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/quickbook-square.svg").then(
        (module) => module.default
      ),
  },
  royalmail: {
    primary: () =>
      import("assets/brand_logos/integrations/royalmail.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/royalmail-square.png").then(
        (module) => module.default
      ),
  },

  shipstation: {
    primary: () =>
      import("assets/brand_logos/integrations/shipstation.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/shipstation-square.svg").then(
        (module) => module.default
      ),
  },
  shopifyplus: {
    primary: () =>
      import("assets/brand_logos/integrations/shopifyplus.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/shopifyplus-square.png").then(
        (module) => module.default
      ),
  },
  shopifypos: {
    primary: () =>
      import("assets/brand_logos/integrations/shopifypos.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/shopifypos-square.png").then(
        (module) => module.default
      ),
  },
  square: {
    primary: () =>
      import("assets/brand_logos/integrations/square.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/square-square.svg").then(
        (module) => module.default
      ),
  },
  stampsdotcom: {
    primary: () =>
      import("assets/brand_logos/integrations/stamps.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/stamps-square.svg").then(
        (module) => module.default
      ),
  },
  stripe: {
    primary: () =>
      import("assets/brand_logos/integrations/stripe.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/stripe-square.svg").then(
        (module) => module.default
      ),
  },
  tforce: {
    primary: () =>
      import("assets/brand_logos/integrations/tforce.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/tforce-square.svg").then(
        (module) => module.default
      ),
  },
  uberrush: {
    primary: () =>
      import("assets/brand_logos/integrations/uberrush.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/uberrush-square.png").then(
        (module) => module.default
      ),
  },
  walmart: {
    primary: () =>
      import("assets/brand_logos/integrations/walmart.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/walmart-square.svg").then(
        (module) => module.default
      ),
  },
  whistl: {
    primary: () =>
      import("assets/brand_logos/integrations/whistl.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/whistl-square.svg").then(
        (module) => module.default
      ),
  },
  wooCommerce: {
    primary: () =>
      import("assets/brand_logos/integrations/woocommerce.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/woocomerce-square.svg").then(
        (module) => module.default
      ),
  },
  yodel: {
    primary: () =>
      import("assets/brand_logos/integrations/yodel.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/yodel-square.svg").then(
        (module) => module.default
      ),
  },
  magento: {
    primary: () =>
      import("assets/brand_logos/integrations/magento.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/magento-square.svg").then(
        (module) => module.default
      ),
  },
  shopify: {
    primary: () =>
      import("assets/brand_logos/integrations/shopify.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/shopify-square.svg").then(
        (module) => module.default
      ),
  },
  ups: {
    primary: () =>
      import("assets/brand_logos/integrations/ups.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/ups-square.svg").then(
        (module) => module.default
      ),
  },
  usps: {
    primary: () =>
      import("assets/brand_logos/integrations/usps.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/usps-square.svg").then(
        (module) => module.default
      ),
  },
  facebookshop: {
    primary: () =>
      import("assets/brand_logos/integrations/meta.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/meta-square.svg").then(
        (module) => module.default
      ),
  },
  instagramshop: {
    primary: () =>
      import("assets/brand_logos/integrations/instagram.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/instagram-square.svg").then(
        (module) => module.default
      ),
  },
  airtable: {
    primary: () =>
      import("assets/brand_logos/integrations/airtable.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/airtable-square.svg").then(
        (module) => module.default
      ),
  },
  gls: {
    primary: () =>
      import("assets/brand_logos/integrations/gls.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/gls-square.svg").then(
        (module) => module.default
      ),
  },
  printnode: {
    primary: () =>
      import("assets/brand_logos/integrations/PrintNode.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/printnode-square.svg").then(
        (module) => module.default
      ),
  },
  speedee: {
    primary: () =>
      import("assets/brand_logos/integrations/speedee.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/speedee-square.svg").then(
        (module) => module.default
      ),
  },
  tiktok: {
    primary: () =>
      import("assets/brand_logos/integrations/tiktok.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/tiktok-square.svg").then(
        (module) => module.default
      ),
  },
  verifymyage: {
    primary: () =>
      import("assets/brand_logos/integrations/verifymyage.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/verifymyage-square.svg").then(
        (module) => module.default
      ),
  },
  shippo: {
    primary: () =>
      import("assets/brand_logos/integrations/shippo.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/shippo-square.svg").then(
        (module) => module.default
      ),
  },
  bydesign: {
    primary: () =>
      import("assets/brand_logos/integrations/bydesign.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/bydesign-square.png").then(
        (module) => module.default
      ),
  },
  firstmile: {
    primary: () =>
      import("assets/brand_logos/integrations/firstmile.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/firstmile-square.svg").then(
        (module) => module.default
      ),
  },
  flexport: {
    primary: () =>
      import("assets/brand_logos/integrations/flexport.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/flexport-square.svg").then(
        (module) => module.default
      ),
  },
  amazonShipping: {
    primary: () =>
      import("assets/brand_logos/integrations/amazon-shipping.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/amazonshipping-square.svg").then(
        (module) => module.default
      ),
  },
  lasership: {
    primary: () =>
      import("assets/brand_logos/integrations/lasership.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/lasership-square.svg").then(
        (module) => module.default
      ),
  },
  ontrac: {
    primary: () =>
      import("assets/brand_logos/integrations/ontrac.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/ontrac-square.svg").then(
        (module) => module.default
      ),
  },
  gso: {
    primary: () =>
      import("assets/brand_logos/integrations/gso.png").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/gso-square.png").then(
        (module) => module.default
      ),
  },
  linkwatehousing: {
    primary: () =>
      import("assets/brand_logos/integrations/linkwarehousin.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/linkswarehousing-square.svg").then(
        (module) => module.default
      ),
  },
  amazonprime: {
    primary: () =>
      import("assets/brand_logos/integrations/amazon-prime.svg").then(
        (module) => module.default
      ),
    square: () =>
      import("assets/brand_logos/square/amazonprime-square.svg").then(
        (module) => module.default
      ),
  },
};

export default logos;
