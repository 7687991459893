import { useState } from "react";
import ReactPlayer from "react-player";
import previewVideoDefault from "assets/images/preview-video-default.jpg";
import playIcon from "assets/icons/play-button.svg";

interface VideoSectionProps {
  video?: string;
  previewVideo?: string;
}

const VideoSection: React.FC<VideoSectionProps> = ({ video, previewVideo }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <>
      <div className="video-wrapper">
        <img
          src={previewVideo || previewVideoDefault}
          alt="Skulabs video preview"
          className="preview"
        />
        {!isPlaying && (
          <div className="play-button" onClick={handlePlayClick}>
            <img src={playIcon} alt="Play icon" />
          </div>
        )}
        <div
          className="player-screen-size"
          style={{ zIndex: isPlaying ? "1" : "-1" }}
        >
          {isPlaying && (
            <ReactPlayer
              playing={isPlaying}
              url={video}
              controls
              muted
              width="100%"
              height="100%"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VideoSection;
