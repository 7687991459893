import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { raitingCompanies, StarRating } from "../Reviews/Reviews";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import LogoCarousel from "components/Layouts/LogoCarousel/LogoCarousel";
import SupportSection from "components/Layouts/Support/SupportSection";
import PricingTable from "Pages/Pricing/PricingTable";
import featureData from "data/featuresData";
import paths from "helpers/paths";

const keysForCarousel1 = [
  "shopifypos",
  "shopify",
  "amazon",
  "ebay",
  "walmart",
  "etsy",
  "bigCommerce",
  "magento",
  "shopifyPlus",
  "indiegogo",
  "square",
  "kickstarter",
];
const keysForCarousel2 = [
  "paypal",
  "stripe",
  "fedex",
  "usps",
  "ups",
  "dhl",
  "easypost",
  "canadapost",
  "amazonShipping",
  "ontrac",
  "flexport",
];

const PricingNew = () => {
  const feature = featureData[paths.features].content;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const plan = searchParams.get("plan");
  const stage = searchParams.get("stage");

  const shouldShowPricingTable =
    plan === "basic2023" || plan === "pro2023" || plan === "enterprise2023";
  {
    shouldShowPricingTable && <PricingTable />;
  }
  return (
    <>
      <Helmet>
        <title>Plans & Pricing - SKULabs</title>
        <meta
          name="description"
          content="Compare SKULabs pricing plans and features to choose the best fit for your business."
        />
      </Helmet>
      <main>
        <div className="pricing-head container">
          <div className="pricing-head-info">
            <h1>Get a Quote Perfect for Your Needs</h1>
            <p>
              We offer personalized quotes for every client, recognizing that
              each business has unique needs, making a one-size-fits-all
              approach impossible.
            </p>
            <p>
              To deliver an accurate estimate, one of our experienced Account
              Executives will gather key information about your business and
              specific requirements. Afterward, we’ll provide you with a
              comprehensive pricing breakdown and analysis, highlighting areas
              where you can save and optimize your operations. Contact us today
              to get started!
            </p>
            <div className="tags">
              <div className="tag">30 day money back guarantee</div>
              <div className="tag">No contracts or onboarding fees</div>
            </div>
            <LiveDemoForm link="/call" buttonText="Get Your Quote" />
          </div>
          <div className="raiting-companies">
            {raitingCompanies.map((company) => (
              <a
                key={company.href}
                href={company.href}
                className="testimonial-company"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Read reviews on ${company.name}`}
              >
                <img
                  src={company.logo}
                  alt={`${company.name} logo`}
                  loading="lazy"
                  width="100px"
                  height="auto"
                />
                <p className="testimonial-annotation">{company.annotation}</p>
                <StarRating stars={5} />
              </a>
            ))}
          </div>
        </div>
      </main>

      <div className="pricing-fullinfo">
        <div className="landing-integrations-content container">
          <h2>Integrated with widely-used eCommerce services</h2>
          <div className="info">
            <p>
              Experience seamless transactions with our service, seamlessly
              integrated with popular platforms for your ultimate convenience.
              Efficiently manage your goods and supplies
            </p>
            <Link className="link-more" to="/integrations">
              View All Integrations{" "}
              <img
                className="arrow"
                src="icons/links-arrow.svg"
                alt="Arrow"
                width="24"
                height="24"
              />
            </Link>
          </div>
        </div>
        <div className="landing-integrations-slider">
          <LogoCarousel direction="left" keysToShow={keysForCarousel1} />
          <LogoCarousel direction="right" keysToShow={keysForCarousel2} />
        </div>
        <div className="pricing-features container">
          <h2>Application Features</h2>
          <div className="features-items capabilities">
            {feature.map((feature, index) => (
              <div className="feature-item capabilitie" key={index}>
                <img
                  src={feature.icon}
                  alt={feature.title}
                  width="48"
                  height="48"
                />
                <div className="capabilities-info">
                  {feature.link && (
                    <Link to={feature.link}>
                      <h3 className="feature-title title">{feature.title}</h3>
                    </Link>
                  )}
                  <p className="feature-description">{feature.description}</p>
                  {feature.link && (
                    <Link className="link-more" to={feature.link}>
                      About Feature{" "}
                      <img
                        className="arrow"
                        src="icons/links-arrow.svg"
                        alt="Arrow"
                        width="24"
                        height="24"
                      />
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SupportSection />
    </>
  );
};

export default PricingNew;
