import logos from "data/integrationLogos";

//Icons
import multichannels from "assets/icons/multi-channel.svg";
import inventorymanagement from "assets/icons/inventory-management.svg";
import barcode from "assets/icons/barcode.svg";
import delivery from "assets/icons/delivery.svg";
import purchasingreceiving from "assets/icons/purchasing-receiving.svg";
import discount from "assets/icons/discount-icon.svg";
import deliveryPerfomance from "assets/icons/delivery-performace-icon.svg";
import tracking3PL from "assets/icons/tracking-3PL-icon.svg";
import directlyConnection from "assets/icons/directly-connection-icon.svg";
import shipped3PL from "assets/icons/shipped-3pl-icon.svg";

export type Integration = {
  //? Card Information
  logo: () => Promise<string>;
  companyName: string;
  tags: string[];
  category: "Channel" | "Shipping" | "Accounting/Payments" | "3PL" | "Other";
  shortFeatures: string[];
  searchText?: string;

  //? Integration Page Informtaion
  squareLogo?: () => Promise<string>;
  videoPreview?: string;
  video?: string;
  features?: {
    title: string;
    description: string;
  }[];

  //! SEO
  seoTitle?: string;
  seoDescription?: string;
};

const easyPostFeatures = [
  {
    title: "Simple connection",
    description:
      "EasyPost simplifies the process of connecting to multiple carriers and services. Connect your EasyPost account to SKULabs and start shipping in minutes.",
  },
];

export const integrationsList: Integration[] = [
  {
    companyName: "Shopify",
    tags: ["Shopify"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
      "Manage price and cost",
    ],
    category: "Channel",
    logo: logos.shopify.primary,
    squareLogo: logos.shopify.square,
    // video: "https://www.youtube.com/watch?v=tunE6v9prec",
    features: [
      {
        title: "Returns sync",
        description:
          "Your returned inventory in Shopify automatically updates your warehouses in SKULabs when customer service checks «returned to inventory» in Shopify.",
      },
      {
        title: "Our best integration",
        description:
          "Shopify is our most popular integration and we invest a lot into maintaining and adding new features to this integration.",
      },
      {
        title: "Integrate with Shopify Flow",
        description:
          "Connect SKULabs to Shopify Flow using tag-based automation rules in SKULabs.",
      },
      {
        title: "Integrate with Shopify POS",
        description:
          "SKULabs automatically removes inventory for POS sales from assigned warehouses. In-store refunds marked «restock» automatically processes an instant RMA in SKULabs restoring inventory.",
      },
    ],
  },
  {
    companyName: "Shopify Plus",
    tags: ["Shopify plus"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
      "Manage price and cost",
    ],
    features: [
      {
        title: "Simple and powerful Shopify inventory management",
        description:
          "Import all of your Shopify products and set them up as catalog items or kits. Manage inventory across all of your products, locations, and warehouses. Receive incoming stock, deduct stock when shipping orders, and sync inventory to Shopify automatically.",
      },
      {
        title: "Print shipping labels from any carrier",
        description:
          "Import all of your Shopify products and set them up as catalog items or kits. Manage inventory across all of your products, locations, and warehouses. Receive incoming stock, deduct stock when shipping orders, and sync inventory to Shopify automatically.",
      },
    ],
    category: "Channel",
    logo: logos.shopifyplus.primary,
    squareLogo: logos.shopifyplus.square,
  },
  {
    companyName: "Amazon",
    tags: ["US/CA/MX", "Europe", "Pacific", "etc"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.amazon.primary,
    squareLogo: logos.amazon.square,
    features: [
      {
        title: `NEW! Amazon Shipping`,
        description:
          "Purchase Amazon Shipping labels for your Amazon orders in SKULabs making use of the Amazon Prime fulfillment network to ship orders to customers.",
      },
      {
        title: "Seller performance - 99.99%",
        description: `Our customers report to us significant seller performance improvements with staying power. Our in-built best practices ensure your seller performance - by default - regardless of your team's experience selling on Amazon. Dedicated column for "Deliver By Date" and "Ship By Date" in addition to automatic rules to ensure you ship and deliver on-time.`,
      },
      {
        title: "Seller Fulfilled Prime & Multi-Channel Fulfillment",
        description:
          "Dropship using your FBA inventory. Use Amazon Buy Shipping to purchase shipping labels and maintain seller fulfilled prime support.",
      },
      {
        title: "Expertise managing category leading brands",
        description:
          "Our team has empowered brands to become category leaders on Amazon. Using SKULabs, you're working with experts who have seen it all. Whether you're growing or already at the top, we have the experience to back you up.",
      },
      {
        title: "FBA + FBM + Anything",
        description:
          "View your FBA inventory, 3PL inventory, and warehouse inventory in one view. Forecast sales across all of your sales channels - even POS and wholesale. Easily place purchase orders with suppliers and track incoming inventory with expected arrival dates. Eliminate out-of-stock scenarios, maximize sales.",
      },
    ],
  },
  {
    companyName: "WooCommerce",
    tags: ["WooCommerce"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    features: [
      {
        title: `Simple and powerful WooCommerce inventory management`,
        description:
          "Import all of your sales channel listings into a unified catalog and manage inventory for all of your products at once. Count inventory regularly and synchronize inventory changes to WooCommerce. Use barcode scanning to receive inventory from distributors and to pick items for orders.",
      },
      {
        title: "Build kits, bundles, and made-to-order products",
        description: `Build a central catalog for all of your WooCommerce or multichannel listings. Link bundle listings to kits containing multiple products. Manage inventory for each WooCommerce variant separately, and use inventory supplies to assemble custom products.`,
      },
      {
        title: "WooCommerce order management",
        description:
          "Use out order management dashboard to manage orders from all of your sales channels. Use sorting and filtering to find the orders you want to work with. Organize orders and their products with custom tags. Pick, pack, and ship all of your orders in one place.",
      },
      {
        title: "Print shipping labels from any carrier",
        description:
          "Ship WooCommerce orders one by one or all at once in batches. Compare rates from multiple carriers like UPS, FedEx, DHL, and more. Print shipping labels wirelessly from any device through Google Cloud Print.",
      },
    ],
    category: "Channel",
    logo: logos.wooCommerce.primary,
    squareLogo: logos.wooCommerce.square,
  },
  {
    companyName: "eBay",
    tags: ["eBay"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.ebay.primary,
    squareLogo: logos.ebay.square,
  },
  {
    companyName: "Etsy",
    tags: ["Etsy"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.etsy.primary,
    squareLogo: logos.etsy.square,
  },
  {
    companyName: "Walmart",
    tags: ["Walmart", "Walmart Canada"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.walmart.primary,
    squareLogo: logos.walmart.square,
  },
  {
    companyName: "USPS",
    tags: [
      "Up to 90% off!",
      "Official USPS Partner",
      "USPS Connect® eCommerce",
    ],
    shortFeatures: [
      "View Rates",
      "Purchase labels",
      "Shipment & Tracking Notification",
    ],
    features: [...easyPostFeatures],
    category: "Shipping",
    logo: logos.usps.primary,
    squareLogo: logos.usps.square,
    searchText:
      "priority express first class parcel post media mail letter polybag flat rate ground advantage",
  },
  {
    companyName: "UPS",
    tags: ["UPS", "Up to 74% off!"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.ups.primary,
    squareLogo: logos.ups.square,
    searchText: "express saver ground",
  },
  {
    companyName: "FedEx",
    tags: ["FedEx", "Up to 50% off!"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.fedex.primary,
    squareLogo: logos.fedex.square,
    searchText:
      "express onerate one rate smart home ground express saver 2day 2 day 3day 3 day 1day 1 day overnight international priority economy first class parcel post media mail letter polybag flat rate ground advantage",
  },
  {
    companyName: "Amazon Prime",
    tags: [
      "Seller-Fulfilled Prime (SFP)",
      "Buy Shipping & Fulfilled By Merchant (FBM)",
    ],
    shortFeatures: [
      "View Rates",
      "Purchase labels",
      "Shipment & Tracking Notification",
    ],
    category: "Shipping",
    logo: logos.amazonprime.primary,
    squareLogo: logos.amazonprime.square,
    searchText: "BuyShipping",
  },
  {
    companyName: "DHL",
    tags: ["Deutsche Post", "DHL eCommerce"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.dhl.primary,
    squareLogo: logos.dhl.square,
  },
  {
    companyName: "ShipStation",
    tags: ["ShipStation"],
    shortFeatures: ["Shipment & Tracking Notification", "Import shipments"],
    features: [
      {
        title: "Print labels from all your carriers",
        description:
          "Shop between multiple carriers rates and print shipping labels from every shipment provider you use without switching between shipping software. We integrate with USPS, UPS, FedEx, Canada Post, Royal Mail, Amazon Shipping, and dozens of other carriers from around the world.",
      },
      {
        title: "Validate addresses before shipping",
        description:
          "Check if an address is typed in correctly, or if it's a business or residence, with the push of a button. Our address validation system sends the shipment's address to the same service that your shipping carrier uses to determine if a submitted address is correct.",
      },
      {
        title: "Memorized shipment settings and powerful shipping rules",
        description:
          "Spend less time at the scale with our unique shipment memory system. Have your best staff use SKULabs first, trail blazing a set of memorized shipping defaults for your most common package configurations. Use shipping rules and default to automate method, packaging, insurance and more based on number of items, order weight, item tags, order tags, and anything else you can imagine.",
      },
      {
        title: "Process batches of shipments",
        description:
          "Print hundreds of shipping labels in seconds. Just select your orders, check the shipment settings for each of them, and click to submit the shipments and start printing. There's no need to split up shipping jobs by carrier or sales channel.",
      },
    ],
    category: "Shipping",
    logo: logos.shipstation.primary,
    squareLogo: logos.shipstation.square,
  },
  {
    companyName: "QuickBooks",
    tags: ["QuickBooks Online"],
    shortFeatures: [
      "Import invoices as orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    features: [
      {
        title: "Fast, accurate sales order fulfillment",
        description:
          "Sync in sales orders and scan to pick them, Compare rates between shipping carriers, Get live postage and print shipping labels",
      },
      {
        title: "Cloud sync solution with QuickBooks",
        description:
          "Real-time sales receipt sync to QuickBooks, Cloud hosted with 99.99% uptime, USA-based support team 7 days per week",
      },
    ],
    category: "Accounting/Payments",
    logo: logos.quickbooks.primary,
    squareLogo: logos.quickbooks.square,
    searchText: "qbo intuit",
  },
  {
    companyName: "Paypal",
    tags: ["Paypal"],
    shortFeatures: ["Lightweight payment capture solution"],
    category: "Accounting/Payments",
    logo: logos.paypal.primary,
    squareLogo: logos.paypal.square,
  },
  {
    companyName: "Stripe",
    tags: ["Stripe"],
    shortFeatures: ["Lightweight payment capture solution"],
    category: "Accounting/Payments",
    logo: logos.stripe.primary,
    squareLogo: logos.stripe.square,
  },
  {
    companyName: "Authorize",
    tags: ["Authorize.net"],
    shortFeatures: ["Lightweight payment capture solution"],
    category: "Accounting/Payments",
    logo: logos.authorizedotnet.primary,
    squareLogo: logos.authorizedotnet.square,
  },
  {
    companyName: "Amazon S3",
    tags: ["Amazon S3"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Inventory feed",
      "Product feed",
    ],
    category: "Channel",
    logo: logos.amazon.primary,
    squareLogo: logos.amazon.square,
  },
  {
    companyName: "Amazon FBA",
    tags: ["Fulfilled By Amazon (FBA)"],
    shortFeatures: [
      "Forward orders for fulfillment by Amazon (FBA)",
      "Multi-Channel Fulfillment (MCF)",
    ],
    category: "3PL",
    logo: logos.amazon.primary,
    squareLogo: logos.amazon.square,
    searchText: "dropship",
  },

  {
    companyName: "Shopify POS",
    tags: ["Shopify POS"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
      "Manage price and cost",
    ],
    category: "Channel",
    logo: logos.shopifypos.primary,
    squareLogo: logos.shopifypos.square,
  },

  {
    companyName: "Shopify 3PLs",
    tags: ["ShipBob", "ShipHero", "ShipMonk", "etc"],
    shortFeatures: [
      "Any 3PL that integrates with Shopify is supported by SKULab",
    ],
    category: "3PL",
    logo: logos.shopify.primary,
    squareLogo: logos.shopify.square,
  },

  {
    companyName: "DPD",
    tags: ["DPD", "DPD Local"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.dpd.primary,
    squareLogo: logos.dpd.square,
  },
  {
    companyName: "Shippo",
    tags: ["Shippo"],
    shortFeatures: ["Connect carriers", "View Rates", "Purchase labels"],
    category: "Shipping",
    logo: logos.shippo.primary,
    squareLogo: logos.shippo.square,
  },
  {
    companyName: "BigCommerce",
    tags: ["BigCommerce"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.bigcommerce.primary,
    squareLogo: logos.bigcommerce.square,
  },
  {
    companyName: "Magento",
    tags: ["Magento 1", "Magento 2"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.magento.primary,
    squareLogo: logos.magento.square,
  },
  {
    companyName: "Shift4Shop",
    tags: ["Shift4Shop"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.shift4shop.primary,
    squareLogo: logos.shift4shop.square,
  },
  {
    companyName: "Square",
    tags: ["Square"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    features: [
      {
        title: "Connect POS orders from Square to your inventory",
        description:
          "Connect your Square store to your online sales channels through SKULabs. As products are sold in-store, orders are imported into SKULabs and the products' inventory is deducted automatically. Then you can synchronize inventory to Square and your other stores.",
      },
      {
        title: "Simple and powerful Square inventory management",
        description:
          "Import all of your products from all of your sales channels into one unified catalog. Manage inventory for individual items and for product bundles and kits. Receive inventory from suppliers, deduct inventory as you fulfill orders, and sync inventory changes back to Square.",
      },
      {
        title: "Synchronize inventory to online sales channels in real time",
        description:
          "Set SKULabs as the master of inventory for all of your multichannel stores and marketplace listings. As orders are placed, whether online or offline, the required inventory quantities are reserved until they are shipped out. Reserved inventory is deducted from the on hand inventory counts, and the remaining free count is synchronized to Square and other sales channels automatically to prevent overselling.",
      },
    ],
    category: "Channel",
    logo: logos.square.primary,
    squareLogo: logos.square.square,
  },
  {
    companyName: "Indiegogo",
    tags: ["Indiegogo"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
    ],
    category: "Channel",
    logo: logos.indiegogo.primary,
    squareLogo: logos.indiegogo.square,
  },
  {
    companyName: "Kickstarter",
    tags: ["Kickstarter"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
    ],
    category: "Channel",
    logo: logos.kickstarter.primary,
    squareLogo: logos.kickstarter.square,
  },
  {
    companyName: "Remote Data Feed",
    tags: ["FTP", "SFTP", "FTPS", "HTTPS", "JSON", "CSV", "XML"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Manage inventory levels",
    ],
    category: "Channel",
    logo: logos.ftp.primary,
  },
  {
    companyName: "Facebook Shop",
    tags: ["Facebook Shop"],
    shortFeatures: ["Via Shopify"],
    category: "Channel",
    logo: logos.facebookshop.primary,
    squareLogo: logos.facebookshop.square,
  },
  {
    companyName: "Instagram Shop",
    tags: ["Instagram Shop"],
    shortFeatures: ["Via Shopify"],
    category: "Channel",
    logo: logos.instagramshop.primary,
    squareLogo: logos.instagramshop.square,
  },
  {
    companyName: "Google Sheets",
    tags: ["Google Sheets", "Google Drive"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Inventory feed",
      "Product feed",
    ],
    category: "Channel",
    logo: logos.googleDocs.primary,
    squareLogo: logos.googleDocs.square,
  },
  {
    companyName: "TikTok",
    tags: ["TikTok"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Inventory feed",
    ],
    category: "Channel",
    logo: logos.tiktok.primary,
    squareLogo: logos.tiktok.square,
  },
  {
    companyName: "AirTable",
    tags: ["AirTable"],
    shortFeatures: [
      "Import orders for fulfillment",
      "Shipment & Tracking Notification",
      "Inventory feed",
      "Product feed",
    ],
    category: "Channel",
    logo: logos.airtable.primary,
    squareLogo: logos.airtable.square,
  },

  {
    companyName: "Canada Post",
    tags: ["Canada Post"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.canadapost.primary,
    squareLogo: logos.canadapost.square,
  },
  {
    companyName: "EasyPost",
    tags: ["EasyPost"],
    shortFeatures: ["Connect carriers", "View Rates", "Purchase labels"],
    category: "Shipping",
    logo: logos.easypost.primary,
    squareLogo: logos.easypost.square,
  },
  {
    companyName: "Amazon Shipping",
    tags: ["Amazon Shipping"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.amazonShipping.primary,
    squareLogo: logos.amazonShipping.square,
  },
  {
    companyName: "Ontrac",
    tags: ["Ontrac"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.ontrac.primary,
    squareLogo: logos.ontrac.square,
  },
  {
    companyName: "Lazership",
    tags: ["Lazership"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.lasership.primary,
    squareLogo: logos.lasership.square,
  },
  {
    companyName: "ByDesign",
    tags: ["ByDesign"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.bydesign.primary,
    squareLogo: logos.bydesign.square,
  },
  {
    companyName: "FirstMile",
    tags: ["FirstMile"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.firstmile.primary,
    squareLogo: logos.firstmile.square,
  },
  {
    companyName: "flexport",
    tags: ["flexport"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.flexport.primary,
    squareLogo: logos.flexport.square,
  },

  {
    companyName: "APC",
    tags: ["APC"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.apc.primary,
    squareLogo: logos.apc.square,
  },
  {
    companyName: "Aramex",
    tags: ["Aramex"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.aramex.primary,
    squareLogo: logos.aramex.square,
  },
  {
    companyName: "Arrow XL",
    tags: ["Arrow XL"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.arrowxl.primary,
    squareLogo: logos.arrowxl.square,
  },
  {
    companyName: "Asendia",
    tags: ["Asendia", "Asendia KH"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.asendia.primary,
    squareLogo: logos.asendia.square,
  },
  {
    companyName: "Australia Post",
    tags: ["Australia Post"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.australiapost.primary,
    squareLogo: logos.australiapost.square,
  },
  {
    companyName: "CanPar",
    tags: ["CanPar"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.canpar.primary,
    squareLogo: logos.canpar.square,
  },
  {
    companyName: "Colis Privé",
    tags: ["Colis Privé"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.colisprive.primary,
    squareLogo: logos.colisprive.square,
  },
  {
    companyName: "Couriers Please KH",
    tags: ["Couriers Please KH"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.couriersplease.primary,
    squareLogo: logos.couriersplease.square,
  },
  {
    companyName: "Endicia",
    tags: ["Endicia"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.endicia.primary,
    squareLogo: logos.endicia.square,
  },
  {
    companyName: "Fastway",
    tags: ["Fastway"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.fastway.primary,
    squareLogo: logos.fastway.square,
  },
  {
    companyName: "GLS",
    tags: ["GLS"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.gls.primary,
    squareLogo: logos.gls.square,
  },
  {
    companyName: "GSO",
    tags: ["GSO"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.gso.primary,
    squareLogo: logos.gso.square,
  },
  {
    companyName: "LSO",
    tags: ["LSO"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.lso.primary,
    squareLogo: logos.lso.square,
  },
  {
    companyName: "MondialRelay",
    tags: ["MondialRelay"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.mondialrelay.primary,
    squareLogo: logos.mondialrelay.square,
  },
  {
    companyName: "New Zealand Post",
    tags: ["New Zealand Post"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.nzpost.primary,
    squareLogo: logos.nzpost.square,
  },
  {
    companyName: "Ninja Van",
    tags: ["Ninja Van"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.ninjavan.primary,
    squareLogo: logos.ninjavan.square,
  },
  {
    companyName: "Norco",
    tags: ["Norco"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.norco.primary,
    squareLogo: logos.norco.square,
  },
  {
    companyName: "Parcelforce",
    tags: ["Parcelforce"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.parcelforce.primary,
    squareLogo: logos.parcelforce.square,
  },
  {
    companyName: "Postmates",
    tags: ["Postmates"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.postmates.primary,
    squareLogo: logos.postmates.square,
  },
  {
    companyName: "Purolator",
    tags: ["Purolator"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.purolator.primary,
    squareLogo: logos.purolator.square,
  },
  {
    companyName: "Royal Mail",
    tags: ["Royal Mail"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.royalmail.primary,
    squareLogo: logos.royalmail.square,
  },
  {
    companyName: "SpeeDee",
    tags: ["SpeeDee"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.speedee.primary,
    squareLogo: logos.speedee.square,
  },
  {
    companyName: "Stamps",
    tags: ["Stamps"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.stampsdotcom.primary,
    squareLogo: logos.stampsdotcom.square,
  },
  {
    companyName: "TForce Logistics",
    tags: ["TForce Logistics"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.tforce.primary,
    squareLogo: logos.tforce.square,
  },
  {
    companyName: "Uber Rush",
    tags: ["Uber Rush"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.uberrush.primary,
    squareLogo: logos.uberrush.square,
  },
  {
    companyName: "Whistl",
    tags: ["Whistl"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.whistl.primary,
    squareLogo: logos.whistl.square,
  },
  {
    companyName: "Yodel",
    tags: ["Yodel"],
    shortFeatures: [
      "Shipment & Tracking Notification",
      "View Rates",
      "Purchase labels",
    ],
    category: "Shipping",
    logo: logos.yodel.primary,
    squareLogo: logos.yodel.square,
  },
  {
    companyName: "Duoplane",
    tags: ["Duoplane"],
    shortFeatures: ["Order routing"],
    category: "Other",
    features: [
      {
        title: "Vendor Dropshipping",
        description:
          "Connect your Duoplane account to SKULabs and start shipping vendor POs.",
      },
    ],
    logo: logos.duoplane.primary,
    squareLogo: logos.duoplane.square,
  },
  {
    companyName: "Flxpoint",
    tags: ["Flxpoint"],
    shortFeatures: ["Order routing"],
    category: "Other",
    features: [
      {
        title: "Vendor Dropshipping",
        description:
          "Connect your FLXPoint account to SKULabs and speak with the FLXPoint team to route orders into SKULabs for fulfillment based on inventory availability.",
      },
    ],
    logo: logos.flxpoint.primary,
    squareLogo: logos.flxpoint.square,
  },
  {
    companyName: "PrintNode",
    tags: ["PrintNode"],
    shortFeatures: ["Print labels & packing slips"],
    category: "Other",
    features: [
      {
        title: "Seamless printing - any device",
        description:
          "SKULabs Print Client connects all of your printers to any device in your warehouse making printing seamless and efficient (no clicks).",
      },
    ],
    logo: logos.printnode.primary,
    squareLogo: logos.printnode.square,
  },
  {
    companyName: "VerifyMyAge",
    tags: ["VerifyMyAge"],
    shortFeatures: ["Age verification service"],
    category: "Other",
    logo: logos.verifymyage.primary,
    squareLogo: logos.verifymyage.square,
  },
  {
    companyName: "Links Warehousing",
    tags: ["Links Warehousing"],
    shortFeatures: ["Forward orders for fulfillment by your 3PL"],
    category: "3PL",
    logo: logos.linkwatehousing.primary,
    squareLogo: logos.linkwatehousing.square,
    searchText: "dropship",
  },
];

export const channelsData = [
  {
    iconSrc: multichannels,
    title: "Multi-channel visibility",
    description:
      "View your orders from all of your sales channels in one system, enforce business rules and forecast velocity across all orders regardless of channel",
  },
  {
    iconSrc: inventorymanagement,
    title: "Inventory Management",
    description:
      "See stock levels for all of your products at a glance, track inventory across multiple warehouses, and sync inventory changes to all of your sales channels",
  },
  {
    iconSrc: barcode,
    title: "Barcode-based Picking",
    description:
      "Fulfill your orders with 100% accuracy by scanning order items before you ship them. Pick using paper lists or from any mobile device or desktop computer",
  },
  {
    iconSrc: delivery,
    title: "Multi-carrier Shipping",
    description:
      "Rate shop between multiple carriers and print shipping labels from every shipment provider you use. Automatically learns how you prefer to ship as you use it. Easily add your own shipping rules too.",
  },
  {
    iconSrc: purchasingreceiving,
    title: "Purchasing & Receiving",
    description:
      "Create and send purchase orders to all of your distributors in one place. Draft purchases in bulk and scan incoming goods to add them to inventory",
  },
];

export const shippingsData = [
  {
    iconSrc: multichannels,
    title: "Multi-channel orders",
    description:
      "View your orders from all of your sales channels in one system.",
  },
  {
    iconSrc: delivery,
    title: "Multi-carrier Rate Shopping",
    description:
      "Rate shop between multiple carriers and print shipping labels from every shipment provider you use.",
  },
  {
    iconSrc: inventorymanagement,
    title: "Powerful shipping automation",
    description:
      "Automatically learns how you prefer to ship as you use it. Easily add your own shipping rules too.",
  },
  {
    iconSrc: purchasingreceiving,
    title: "Accurate on-time fulfillment",
    description:
      "Built-in best practices guarantee your team ships and delivers orders on-time, every time.",
  },
  {
    iconSrc: discount,
    title: "Keep your volume discounts",
    description:
      "We are happy for you to bring your own account or use ours. We have negotiated rates with most major carriers and pass the savings to you.",
  },
  {
    iconSrc: deliveryPerfomance,
    title: "Delivery Performance 99.9%",
    description:
      "Seller performance standards are built-in to ensure you ship and deliver on-time, every time no matter your team's experience.",
  },
];

export const accountingData = channelsData;

export const threeplData = [
  {
    iconSrc: tracking3PL,
    title: "Orders shipped by 3PL",
    description:
      "Tracking and partial fulfillment status import to SKULabs as shipments on your orders. Work alongside your 3PL from one or multiple warehouses.",
  },
  {
    iconSrc: shipped3PL,
    title: "Your 3PL uses SKULabs",
    description:
      "You can invite your 3PL to use SKULabs on your account with fine-grained permissions to fulfill orders and manage inventory.",
  },
  {
    iconSrc: directlyConnection,
    title: "Direct Integrations",
    description:
      "Contact us to see if we already directly connect to your 3PL. If not, we can work with you to build a custom integration.",
  },
];

export const othersData = channelsData;

export default integrationsList;
