import { Link } from "react-router-dom";
import fingerTarget from "assets/images/integrations/man.webp";

//logos
import amazon from "assets/brand_logos/integrations/amazon.svg";
import shopify from "assets/brand_logos/integrations/shopify.svg";
import ebay from "assets/brand_logos/integrations/ebay.svg";
import walmart from "assets/brand_logos/integrations/walmart.svg";
import qb from "assets/brand_logos/integrations/quickbooks.svg";
import usps from "assets/brand_logos/integrations/usps.svg";
import fedex from "assets/brand_logos/integrations/fedex.svg";
import ups from "assets/brand_logos/integrations/ups.svg";

const TargetIntegration: React.FC = () => {
  return (
    <section className="target-integration">
      <div className="container">
        <div className="finger-target-image">
          <img src={fingerTarget} alt="Finger target" />
        </div>
        <div className="target-integration-info">
          <h2>
            Integration with <span className="accent">Over 50 Companies</span>
          </h2>
          <p>
            Our application provides robust directory management, featuring
            integration with over 50 companies including e-commerce platforms
            and delivery services worldwide. Key features include Inventory
            Management, Barcode-based Picking, Multi-carrier Shipping, and
            Purchasing & Receiving.
          </p>
          <div className="logos">
            <img src={amazon} alt="Amazon" className="logo" />
            <img src={shopify} alt="Shopify" className="logo" />
            <img src={ebay} alt="Ebay" className="logo" />
            <img src={walmart} alt="Walmart" className="logo" />
            <img src={qb} alt="Quickbooks" className="logo" />
            <img src={usps} alt="USPS" className="logo" />
            <img src={ups} alt="UPS" className="logo" />
            <img src={fedex} alt="Fedex" className="logo" />
          </div>
          <Link to={"/integrations"} className="cta">
            Other Integrations
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TargetIntegration;
